
.content-registrar{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
}

.form-content{
    width: 22rem;
    margin: auto;
    border: 1px solid #D9D9D9;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #FFFFFF;
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
}

.form-inputs input{
    font-size: 14px;
}

.img-fondo {
    width: 60%;
}

.img-fondo img{
    width: 95%;
}

.row{
    grid-gap: 15px 0px !important;
}

.login-section{
    min-height: 100vh;
    background: linear-gradient(rgba(92, 94, 98, 0.5), rgba(92,94,98,0.3)),
    url('../assets/fondo_login.jpg') no-repeat center center fixed;
    background-size: cover;
}


.form-login{
    width: 25rem;
    margin: auto;
    border: 1px solid #D9D9D9;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #FFFFFF;
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
}

.section-form-login-user{
    display: flex;
    justify-content: center;
    background-color: #00A3FF;
    min-height: 92.5vh; 
}

.form-login-user input{
    font-size: 15px;
}

.form-login-user button{
    color: #FFFFFF;
    background-color: #00A3FF;
}

.form-login-user p{
    font-size: 13px;
}

.span-link{
    cursor: pointer;
    font-weight: 600;
    color: #3C84AB;
}

.form-login-user .error{
    font-size: 12px;
    color: red;
}

.navRight a{
    cursor: pointer;
}

.dropdown-text{
    font-size: 14px;
}

.navRight .dropdown .dropdown-toggle{
    color: #3C84AB;
}
