.card-container{
    display: flex;
    width: 80%;
    margin: auto;
    height: auto;
    background-color: #fff;
    box-shadow: 1px 1px 4px 2px rgba(0,0,0, .2);
    align-items: center;
    padding: 20px;
}

.img-container{
    padding: 10px;
}

.img-container > img{
    width: 6rem;
    height: 6rem;
}

.info-card{
    width: 25rem;
    font-size: 14px;
}

.validar-section{
    display: flex;
}