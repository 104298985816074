@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap');

body{
    font-family: 'Poppins', sans-serif;
}

h4{
    font-weight: 400;
    font-size: 28px;
    color: rgb(43, 58, 85)
}
/*NAVBAR*/
.navBar{
    /* position: absolute; */
    width: 100%;
    padding: 16px 206px;
    background-color: #FFFFFF;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.navBar .content-nav{
    max-width: 1200px;
    display: flex;
    margin: auto;
    justify-content: space-between;
}

.navBar img{
    width: 80px;
    margin-left: 15px;
}

.nav-auth{
    display: flex;
    align-items: center;
}

.navRight .nav-auth > img{
    width: 35px;
    border-radius: 50%;
}

/* FORMULARIO */
.form-section{
    max-width: 1200px;
    display: flex;
    margin: auto;
    /* padding: 0 180px; */
}

.cuadrado {
    width: 20rem;
    height: 30rem;
    background: #ECF9FF;
    display: flex;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
}

.mostrar-filtro{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.row{
    grid-gap: 30px 0px;
    margin:0;
}

.section{
    max-width: 980px;
    margin: auto;
    color: #304659;
}

.icons-style{
    color: #4D77FF;
}

@media screen and (max-width: 1600px) {
    
    
}


@media screen and (max-width: 1350px) {

    .form-section{
        flex-direction: column;
        /* padding: 0 180px; */
    }

    .cuadrado {
        margin: auto;
        width: 237px;
        height: 420px;
        background: #BCEFFF;
        display: flex;
        align-items: center;
        justify-content: center;
    }

}


@media screen and (max-width: 500px) {
    .form-section{
        padding: 0;
    }

    .form-inputs{
        margin: 0;
    }

    .cuadrado {
        margin: auto;
        width: 169px;
        height: 285px;
        background: #ECF9FF;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .navBar{
        padding: 15px;
        width: 100%;
        height: 50px;
    }

    .navBar img{
        width: 80px;
        margin-left: 8px;
    }
    
    .img-portada{
        display: none;
    }
    
    .search-section{
        padding-top: 35px;
    }
    
    .img-vector{
        height: 15rem;
        width: 100%;
    }

    .info-section{
        display: flex;
        flex-direction: column;
    }

    .botones-info{
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .container-section{
        margin: 0;
        
    }

    .container-servicios{
        display: flex;
        flex-direction: column;
        align-items: center;

    }

    .user-section{
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .valoracion-header{
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .container-rating{
        padding: 0;
    }

    .search-section{
        background-color: #ECF9FF;
        background-image: none;
        height: 20rem;
    }

    .search-inputs-section{
        margin: 1rem;
    }

    .search-inputs{
        height: 12rem;
        display: flex;
        /* flex-direction: column; */
        align-items: center;
    }

    .barras{
        display: none;
    }
}

