/* PERFIL DOCTOR */
.info-section{
    max-width: 980px;
    display: flex;
}

.info-content{
    font-size: 15px;
    margin-top: 3rem;
    margin-left: 2rem;
    width: 80%;
    /* padding-left: 5rem; */
}

.redes{
    font-size: 20px;
    text-decoration: none;
}

.red{
    text-decoration: none;
}

.container-section{
    max-width: 980px;
    margin-left: 5rem;
}

.container-cards{
    display: flex;
    flex-wrap: wrap;
}

.card-servicio{
    width: 18rem;
    height: 13rem;
    margin: 15px;
    padding: 2rem 18px;
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
}

.card-servicio p{
    font-size: 15px;
}

.container-rating{
    color: #3C84AB;
    padding: 0 7rem;
}

.container-rating .opinion{
    font-size: 15px;
}

.user-section img{
    width: 3rem;
    height: 3rem;
}
.info-content .info-section{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.datos-section .card{
    width: 10rem;
    height: 5rem;
    padding-top: 10px;
    margin: 15px;
    align-items: center;
    justify-content: center;
}

.botones-info{
    display: flex;
}

.valoracion-box{
    margin-top: 20px;
}