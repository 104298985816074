/* VISTA PRINCIPAL */
.section-vector{
    /* position: absolute; */
    width: 100%;
    /* padding-top: 5rem; */
}
.img-vector{
    /* height: 25rem; */
    width: 100%;
}

.search-section{
    background-image: url('../../../assets/vector2.png');
    background-repeat: no-repeat;
    background-size: cover;
    height: 70vh;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.search-section .img-portada{
    width: 520px;
}

.search-inputs-section{
    margin: 20%;
}

.search-inputs{
    background-color: rgba(53, 152, 192, 0.88);
    border-radius: 20px;
    max-width: 750px;
    height: 7rem;

}

.search-inputs label{
    color: #FFFFFF;
    font-size: 17px;
}

.search-container{
    background-color: #ECF9FF;
    display: flex;
    align-items: center;
    justify-content: center;
}

.doctores-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.container-cards{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.card-search{
    height: 234px;
    width: 200px;
    border-radius: 10px;
    padding: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1rem;
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
}

.container-cards {
    max-width: 1000px;
    text-align: center;
}


.img-format-search {
    width: 7rem;
    height: 7rem;
    border-radius: 50%;
    /* border: #1C82AD solid 0.5px; */
    
    /* margin: auto; */
}

.img-format {
    width: 9rem;
    height: 9rem;
    border-radius: 50%;
    /* margin: auto; */
}

.section-info{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.cards-especialidades{
    display: flex;
    flex-wrap: wrap;
    max-width: 1000px;
    /* justify-content: space-between; */
}

.card{
    box-shadow: 5px 5px 5px 3px rgba(0, 0, 0, 0.3);
}

.card-especialidad{
    width: 320px;
    margin: 1rem;
}

.card-especialidad img{
    height: 14rem;
}

.card-especialidad p{
    font-size: 14px;
}

.card-info button{
    margin-left: 2.5rem;
    margin-bottom: 12px;
    background-color: #00A3FF;
    color: #FFFFFF;
    font-weight: 400;
}

